export const itemChecker = element => {
		//FIX FILTER ITEMS AFTER AJAX
		if (typeof element === 'undefined' || element === null) var element = false

		if (element) var object = $(element)
		else var object = $('[data-subdrawer] .select-options')

		$(object).each(function() {
			var _this = $(this),
				selected = function(_this) {
					var array = []
					$('input.selected', _this).each(function() {
						array.push(
							'<span>' +
								$(this)
									.parent()
									.find('span')
									.eq(1)
									.text() +
								'</span>',
						)
					})

					if (array.length == 0) array.push('<span>None selected</span>')

					return array.join('')
				},
				selectedhtml = selected(_this),
				text = 'div[data-filter-selection="' + $(this).attr('data-panel') + '"]',
				texthtml = $(text)
					.find('p')
					.html()

			if (selectedhtml != texthtml) {
				$(text)
					.find('p')
					.html(selectedhtml)
			}
		})
	},
	ajaxChange = function(select, _this) {
		//AJAX INIT FOR FILTER SELECTS
		var name = $(_this).attr('data-name'),
			type = $(_this).attr('data-type') || null,
			clearDate = 0,
			values = new Array(),
			all = new Array(),
			form = $(_this)
				.closest('form')
				.attr('id'),
			id = $(_this).attr('id')

		$('#' + form)
			.find('[data-number]')
			.each(function() {
				var itemSelected = new Array()

				if ($(this).is('select')) {
					$(this)
						.find('option:selected')
						.each(function() {
							if ($(this).val() && $(this).val() != '') itemSelected.push($(this).val())
						})
				} else {
					if ($(this).val() && $(this).val() != '') itemSelected.push($(this).val())
				}

				if (itemSelected.length > 0) {
					all.push({ name: $(this).attr('data-name'), number: $(this).attr('data-number'), values: itemSelected })
				}

				if ($(this).attr('data-number') == select) {
					values = itemSelected
				}
			})

		if (type === 'from' && $('input[data-type="to"]').val() === '') {
			clearDate = 1
		}
		$.nette.ajax({
			type: 'POST',
			url: filter_url,
			data: {
				name: name,
				number: select,
				values: values,
				type: type,
				clearDate: clearDate | 0,
				all: all,
			},
			complete: function() {
				itemChecker()
			},
		})
	},
	filterSelectUpdate = function(_this) {
		//SELECTS FUNCTION
		var ul = $(_this)
				.parent()
				.parent(),
			select = '#' + $(ul).attr('data-select'),
			multiple = $(select).attr('multiple') ? true : false,
			empty = $('option[value=""]', select).length > 0 ? true : false,
			option = 'option[value="' + $(_this).attr('data-key') + '"]'

		if ($(_this).hasClass('selected')) {
			if (empty || multiple) {
				$(option, select)
					.prop('selected', false)
					.change()
				$(_this).removeClass('selected')
				if (empty && !multiple) $(_this).prop('checked', false)

				itemChecker(ul)
			}
		} else {
			$(option, select)
				.prop('selected', true)
				.change()
			if (!multiple) {
				$(ul)
					.find('.radio-btn')
					.find('input')
					.removeClass('selected')
			}
			$(_this).addClass('selected')

			itemChecker(ul)
		}
	},
	filterSelectChoose = function(_this, mark, unmark) {
		var ul = $(_this)
				.parent()
				.parent()
				.find('.select-options'),
			select = '#' + $(ul).attr('data-select')

		if ($(_this).hasClass('selected')) {
			$(_this)
				.parent()
				.find('.checkmark__title')
				.text(mark)
			$(ul)
				.find('.checkbox-btn')
				.find('input')
				.prop('checked', false)
				.removeClass('selected')
			$('option', select).prop('selected', false)
			$(select).change()
			$(_this).removeClass('selected')

			itemChecker(ul)
		} else {
			$(_this)
				.parent()
				.find('.checkmark__title')
				.text(unmark)
			$(ul)
				.find('.checkbox-btn')
				.find('input')
				.prop('checked', true)
				.addClass('selected')
			$('option', select).prop('selected', true)
			$(select).change()
			$(_this).addClass('selected')

			itemChecker(ul)
		}
	},
	filterSelectSearch = function(_this) {
		var _init = $(_this).attr('data-initialize') ? true : false,
			_ul = $(_this)
				.parent()
				.parent()
				.find('.select-options')

		if (_init) return false

		$(_this).keyup(function() {
			var li = $(_ul).find('.checkbox-btn')
			for (var i = 0; i < li.length; i++) {
				var list = $(li)
					.eq(i)
					.find('.checkmark__title')
					.text()
				if (
					list.toLowerCase().indexOf(
						$(this)
							.val()
							.toLowerCase(),
					) >= 0
				)
					$(li)
						.eq(i)
						.removeAttr('style')
				else
					$(li)
						.eq(i)
						.hide()
			}
		})

		$(_this).attr('data-initialize', 'true')
	},
	filterDate = function(element) {
		if (typeof element === 'undefined' || element === null) return false

		var _this = '[data-subdrawer].' + element,
			min =
				$(_this)
					.find('input[data-type="from"]')
					.attr('data-min') ||
				$(_this)
					.find('input[data-type="to"]')
					.attr('data-min'),
			max =
				$(_this)
					.find('input[data-type="from"]')
					.attr('data-max') ||
				$(_this)
					.find('input[data-type="to"]')
					.attr('data-max'),
			dayNames = $(_this)
				.find('input[data-type="from"]')
				.attr('data-translate-days')
				.split(',') ||
				$(_this)
					.find('input[data-type="to"]')
					.attr('data-translate-days')
					.split(',') || ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
			mntNames = $(_this)
				.find('input[data-type="from"]')
				.attr('data-translate-months')
				.split(',') ||
				$(_this)
					.find('input[data-type="to"]')
					.attr('data-translate-months')
					.split(',') || [
					'January',
					'February',
					'March',
					'April',
					'May',
					'June',
					'July',
					'August',
					'September',
					'October',
					'November',
					'December',
				]

		$(_this)
			.find('.calendar-draw')
			.datepickerui({
				firstDay: 1,
				minDate: min,
				maxDate: max,
				monthNames: mntNames,
				dayNamesMin: dayNames,
				changeMonth: true,
				changeYear: true,
				numberOfMonths: [1, 1],
				nextText: '',
				prevText: '',
				beforeShowDay: function(date) {
					var date1 =
						$(_this).find('input[data-type="from"]').length > 0
							? $.datepicker.parseDate(
									$.datepicker._defaults.dateFormat,
									$(_this)
										.find('input[data-type="from"]')
										.val(),
							  )
							: ''
					var date2 =
						$(_this).find('input[data-type="to"]').length > 0
							? $.datepicker.parseDate(
									$.datepicker._defaults.dateFormat,
									$(_this)
										.find('input[data-type="to"]')
										.val(),
							  )
							: ''
					return [
						true,
						date1 && (date.getTime() == date1.getTime() || (date2 && date >= date1 && date <= date2))
							? 'dp-highlight'
							: '',
					]
				},
				onSelect: function(dateText, inst) {
					var date1 = $.datepicker.parseDate(
						$.datepicker._defaults.dateFormat,
						$(_this)
							.find('input[data-type="from"]')
							.val(),
					)
					var date2 = $.datepicker.parseDate(
						$.datepicker._defaults.dateFormat,
						$(_this)
							.find('input[data-type="to"]')
							.val(),
					)
					var selectedDate = $.datepicker.parseDate($.datepicker._defaults.dateFormat, dateText)
					var date_label = 'div[data-filter-selection="' + element + '"]'

					if (!date1 || date2) {
						$(_this)
							.find('input[data-type="from"]')
							.val(dateText)
						$(_this)
							.find('.cal-from')
							.text(dateText)
						$(_this)
							.find('input[data-type="to"]')
							.val('')
						$(_this)
							.find('.cal-to')
							.text('')
						$(_this)
							.find('input[data-type="from"]')
							.change()
						$(date_label)
							.find('p span')
							.text(dateText)
						$(this).datepickerui()
					} else if (selectedDate < date1) {
						$(_this)
							.find('input[data-type="to"]')
							.val(
								$(_this)
									.find('input[data-type="from"]')
									.val(),
							)
						$(_this)
							.find('.cal-to')
							.text(
								$(_this)
									.find('input[data-type="from"]')
									.val(),
							)
						$(_this)
							.find('input[data-type="from"]')
							.val(dateText)
						$(_this)
							.find('.cal-from')
							.text(dateText)
						$(_this)
							.find('input[data-type="from"]')
							.change()
						$(date_label)
							.find('p span')
							.text(
								dateText +
									' - ' +
									$(_this)
										.find('input[data-type="to"]')
										.val(),
							)
						$(this).datepickerui()
					} else {
						$(_this)
							.find('input[data-type="to"]')
							.val(dateText)
						$(_this)
							.find('.cal-to')
							.text(dateText)
						$(_this)
							.find('input[data-type="to"]')
							.change()
						$(date_label)
							.find('p span')
							.text(
								$(_this)
									.find('input[data-type="from"]')
									.val() +
									' - ' +
									dateText,
							)
						$(this).datepickerui()
					}
				},
			})
	}
