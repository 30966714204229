import { modifier } from 'constant'
import { useEvent } from 'hooks'
import { cn } from 'utils'

import { constant } from './constant'
import { selectors } from './selector'

export const Accordion = (() => {
	const showTarget = (show: boolean, id: string) => {
		const target = selectors.getTarget(id)

		if (!target) {
			console.warn(`missing accordion id: ${id}`)
			return
		}

		if (show) {
			cn.addClass(target, modifier.active)
		} else {
			cn.removeClass(target, modifier.active)
		}
	}

	const handleHandler = (handler: HTMLElement) => {
		const click = useEvent<MouseEvent>(handler, 'click')
		const id = handler.getAttribute(constant.selector.handler)
		const rotate = handler.getAttribute(constant.modifier.handlerRotate)

		click.register(() => {
			if (!id) return
			if (cn.hasClass(handler, modifier.active)) {
				if (rotate && rotate === 'true') cn.removeClass(handler, modifier.rotate)
				cn.removeClass(handler, modifier.active)
				showTarget(false, id)
			} else {
				if (rotate && rotate === 'true') cn.addClass(handler, modifier.rotate)
				cn.addClass(handler, modifier.active)
				showTarget(true, id)
			}
		})
	}

	const handleGroupHandler = (handler: HTMLElement) => {
		const click = useEvent(handler, 'click')
		const groupName = handler.getAttribute(constant.selector.groupHandler)
		const activeText = handler.getAttribute(constant.selector.groupHandlerActiveText)
		const inactiveText = handler.getAttribute(constant.selector.groupHandlerInactiveText)
		if (!groupName) return

		const group = selectors.getGroup(groupName)
		if (!group) return

		click.register(() => {
			if (cn.hasClass(handler, modifier.active)) {
				cn.removeClass(handler, modifier.active)
				if (inactiveText) {
					handler.textContent = inactiveText
				}
			} else {
				cn.addClass(handler, modifier.active)
				if (activeText) {
					handler.textContent = activeText
				}
			}

			for (let i = 0, { length } = group; i < length; i++) {
				const item = group[i]
				const itemID = item.getAttribute(constant.selector.handler)
				if (!itemID) continue

				const itemContent = selectors.getTarget(itemID)
				if (!itemContent) continue

				if (cn.hasClass(item, modifier.active)) {
					cn.removeClass(item, modifier.active)
					cn.removeClass(itemContent, modifier.active)
				} else {
					cn.addClass(item, modifier.active)
					cn.addClass(itemContent, modifier.active)
				}
			}
		})
	}

	const initGroupsHandlers = () => {
		const groupsHandlers = selectors.getGroupsHandlers()

		for (let i = 0, { length } = groupsHandlers; i < length; i++) {
			const groupHandler = groupsHandlers[i]
			handleGroupHandler(groupHandler)
		}
	}

	const initHandler = () => {
		const handler = selectors.getHandlers()

		for (let i = 0, { length } = handler; i < length; i++) {
			const h = handler[i]

			if (!h) continue
			handleHandler(h)
		}
	}

	const init = () => {
		initHandler()
		initGroupsHandlers()
	}

	return {
		init,
		constant,
		selectors,
	}
})()
