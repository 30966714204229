import M from 'materialize-css'
import { cn } from 'utils'

const WRAPPER_CLASS = 'select-wrapper'

const Select = () => {
	const init = () => {
		const targets = document.querySelectorAll('select')

		for (let i = 0, { length } = targets; i < length; i++) {
			const item = targets[i]
			const { parentNode } = item
			if (parentNode && cn.hasClass(parentNode as HTMLElement, WRAPPER_CLASS)) continue
			M.FormSelect.init(item, {
				dropdownOptions: {
					container: document.body,
				},
			})
		}
	}

	return {
		init,
	}
}

export default Select
