import { modifier, module } from 'constant'
import { getFilterSelectors } from 'selectors'
import { useAnimation, useEvent, useState } from 'hooks'
import { cn } from 'utils'

type State = {
	filterHandlers?: NodeListOf<HTMLElement>
	filterResets?: NodeListOf<HTMLElement>
	activeFilters?: HTMLElement
	filtered: boolean
	callback?: ModuleCallbacks
}

type ModuleCallbacks = {
	onReset?: (() => void)[]
}

export const Filter = (() => {
	const scroll = useEvent(window, 'scroll')
	const { state, setState } = useState<State>({
		filtered: false,
		pageYScrolled: false,
	})

	const dispatchResetCallbacks = () => {
		const { callback } = state

		if (!callback) return
		const { onReset } = callback

		if (!onReset) return
		const resetCallbacksLength = onReset.length

		for (let i = 0; i < resetCallbacksLength; i++) {
			const cb = onReset[i]

			if (!cb) continue
			cb()
		}
	}

	const submitFilters = () => {
		const { filterHandlers } = state
		// TODO: Refactor
		const submitter = document.querySelector<HTMLInputElement>('.filter-submit')

		if (!submitter) return
		submitter.click()

		if (!filterHandlers) return
		const filterHandlersLength = filterHandlers.length

		if (filterHandlersLength === 0) return

		for (let i = 0; i < filterHandlersLength; i++) {
			const handler = filterHandlers[i]
			cn.addClass(handler, modifier.filtered)
		}

		setState({ filtered: true })
	}

	const handleResetFilters = () => {
		// TODO: Refactor
		const resetter = document.querySelector<HTMLInputElement>('input[name="_reset"]')

		if (!resetter) return
		resetter.click()

		dispatchResetCallbacks()
	}

	const setResetEvents = (reset: HTMLElement) => {
		const { filterHandlers } = state
		const click = useEvent(reset, 'click')

		click.register(() => {
			handleResetFilters()

			if (!filterHandlers) return
			const filterHandlersLength = filterHandlers.length

			if (filterHandlersLength === 0) return

			for (let i = 0; i < filterHandlersLength; i++) {
				const handler = filterHandlers[i]
				cn.removeClass(handler, modifier.filtered)
			}

			setState({ filtered: false })
		})
	}

	const handleActiveFiltersScrolled = () => {
		const { activeFilters, pageYScrolled } = state

		if (!activeFilters) return
		const topScrollPosition = window.pageYOffset || document.documentElement.scrollTop

		if (topScrollPosition > 1) {
			if (pageYScrolled) return
			cn.addClass(activeFilters, modifier.scroll)
			useAnimation({
				el: activeFilters,
				from: { transform: `translateY(0px)` },
				to: { transform: `translateY(-${module.header.size.scrolledDiff}px)` },
				duration: 200,
			})
			setState({ pageYScrolled: true })
		} else {
			if (!pageYScrolled) return
			cn.removeClass(activeFilters, modifier.scroll)
			useAnimation({
				el: activeFilters,
				from: { transform: `translateY(-${module.header.size.scrolledDiff}px)` },
				to: { transform: `translateY(0px)` },
				duration: 200,
			})
			setState({ pageYScrolled: false })
		}
	}

	const initReset = () => {
		const { filterResets } = state

		if (!filterResets) return
		const filterResetsLength = filterResets.length

		if (filterResetsLength === 0) return
		for (let i = 0; i < filterResetsLength; i++) {
			const reset = filterResets[i]
			setResetEvents(reset)
		}
	}

	const initWindowEvents = () => {
		scroll.register(handleActiveFiltersScrolled)

		handleActiveFiltersScrolled()
	}

	const init = (callback?: ModuleCallbacks) => {
		if (callback) setState({ callback })
		setState({ ...getFilterSelectors() })

		initReset()
		initWindowEvents()
	}

	return {
		init,
		submitFilters,
	}
})()
