import $ from 'jquery'

import { modifier } from 'constant'
import { useEvent, useState } from 'hooks'
import { cn } from 'utils'
import { Loader } from 'component'

import { constant } from './constant'
import { selectors } from './selector'

const SCROLL_THRESHOLD = 100
const LIMIT = 20

export const Table = (() => {
	const { state, setState } = useState({
		allowLazyload: true,
	})

	const handleLoadMore = (id: string, link: string, scope: HTMLElement) => {
		Loader.create(scope, { datatable: true })

		const rows = selectors.getTableRowsById(id)

		// @ts-ignore
		$.nette.ajax({
			method: 'POST',
			url: link,
			dataType: 'json',
			data: {
				offset: rows.length,
			},
			complete: () => {
				Loader.destroy(scope)
				initHover()
			},
			error: () => {
				Loader.destroy(scope)
			},
		})
	}

	const handleScroll = (scope: HTMLElement) => {
		const scroll = useEvent<Event>(scope, 'scroll')
		const id = scope.getAttribute(constant.selector.body)

		if (!id) return
		const table = selectors.getTable(id)

		if (!table) return
		const link = table.getAttribute(constant.selector.lazyLink)

		if (!link) return
		scroll.register(() => {
			const shouldAllow = scope.offsetHeight + scope.scrollTop >= scope.scrollHeight - SCROLL_THRESHOLD
			const shouldDisallow = scope.offsetHeight + scope.scrollTop < scope.scrollHeight - SCROLL_THRESHOLD

			if (shouldAllow && state.allowLazyload) {
				handleLoadMore(id, link, scope)
				setState({ allowLazyload: false })
			}

			if (shouldDisallow && !state.allowLazyload) {
				setState({ allowLazyload: true })
			}
		})
	}

	const validateScrollPosition = (body: HTMLElement) => {
		const id = body.getAttribute(constant.selector.body)
		if (!id) return

		const rows = selectors.getTableRowsById(id)
		if (!rows || (rows && rows.length === LIMIT)) return

		const index = rows.length - LIMIT
		if (!rows[index]) return

		body.scrollTop = rows[index].offsetTop
	}

	const setChildrenHover = (id: string, hover: boolean) => {
		const children = selectors.getTableRowsParentChildren(id)

		for (let i = 0, { length } = children; i < length; i++) {
			const child = children[i]

			if (hover) {
				cn.addClass(child, modifier.hover)
			} else {
				cn.removeClass(child, modifier.hover)
			}
		}
	}

	const setParentHover = (id: string, hover: boolean) => {
		const parent = selectors.getTableRowParentById(id)

		if (hover) {
			cn.addClass(parent, modifier.hover)
		} else {
			cn.removeClass(parent, modifier.hover)
		}
	}

	const handleHover = (scope: HTMLElement) => {
		const mouseEnter = useEvent(scope, 'mouseenter')
		const mouseOut = useEvent(scope, 'mouseout')
		const id = scope.getAttribute(constant.selector.rowId)
		if (!id) return

		mouseEnter.register(() => {
			setChildrenHover(id, true)
		})

		mouseOut.register(() => {
			setChildrenHover(id, false)
		})
	}

	const handleHoverChildren = (scope: HTMLElement) => {
		const mouseEnter = useEvent(scope, 'mouseenter')
		const mouseOut = useEvent(scope, 'mouseout')
		const id = scope.getAttribute(constant.selector.rowParent)
		if (!id) return

		mouseEnter.register(() => {
			setParentHover(id, true)
			setChildrenHover(id, true)
		})

		mouseOut.register(() => {
			setParentHover(id, false)
			setChildrenHover(id, false)
		})
	}

	const initScroll = () => {
		const bodies = selectors.getTablesBodies()

		if (!bodies) return
		for (let i = 0, { length } = bodies; i < length; i++) {
			const body = bodies[i]

			handleScroll(body)
			validateScrollPosition(body)
		}
	}

	const initHover = () => {
		const parents = selectors.getTableRowsParents()
		const children = selectors.getTableRowsChildren()

		for (let i = 0, { length } = parents; i < length; i++) {
			handleHover(parents[i])
		}

		for (let i = 0, { length } = children; i < length; i++) {
			handleHoverChildren(children[i])
		}
	}

	const init = () => {
		initScroll()
		initHover()
	}

	return {
		init,
	}
})()
