import { module, modifier } from 'constant'
import { useEvent, useState, useAnimation } from 'hooks'
import { getHeader, getMenu } from 'selectors'
import { cn } from 'utils'

type State = {
	header?: HTMLElement
	menu?: HTMLElement
	pageYScrolled?: boolean
	shouldScrolledModifier?: boolean
}

const HEADER_HEIGHT = 70
const HEADER_HEIGHT_SCROLLED = HEADER_HEIGHT - module.header.size.scrolledDiff

export const Header = (() => {
	const resize = useEvent(window, 'resize')
	const scroll = useEvent(window, 'scroll')
	const { setState, state } = useState<State>({
		pageYScrolled: false,
		shouldScrolledModifier: true,
	})

	const handleHeaderScrolled = () => {
		const { pageYScrolled, menu, header, shouldScrolledModifier } = state

		if (!menu || !header) return
		const topScrollPosition = window.pageYOffset || document.documentElement.scrollTop

		if (topScrollPosition > 1) {
			if (pageYScrolled) return
			if (!shouldScrolledModifier) cn.addClass(header, modifier.border)
			cn.addClass(header, modifier.scroll)
			useAnimation({
				el: header,
				from: { height: HEADER_HEIGHT },
				to: { height: HEADER_HEIGHT_SCROLLED },
				duration: 200,
			})
			if (window.innerWidth < module.bp.min.lg) {
				useAnimation({
					el: menu,
					from: { transform: `translateY(0px)` },
					to: { transform: `translateY(-${module.header.size.scrolledDiff}px)` },
					duration: 200,
				})
			}
			setState({ pageYScrolled: true })
		} else {
			if (!pageYScrolled) return
			if (!shouldScrolledModifier) cn.removeClass(header, modifier.border)
			cn.removeClass(header, modifier.scroll)
			useAnimation({
				el: header,
				from: { height: HEADER_HEIGHT_SCROLLED },
				to: { height: HEADER_HEIGHT },
				duration: 200,
			})
			if (window.innerWidth < module.bp.min.lg) {
				useAnimation({
					el: menu,
					from: { transform: `translateY(-${module.header.size.scrolledDiff}px)` },
					to: { transform: `translateY(0px)` },
					duration: 200,
				})
			}
			setState({ pageYScrolled: false })
		}
	}

	const handleHeaderMenuPositionFallback = () => {
		const { menu } = state

		if (!menu) return
		const topScrollPosition = window.pageYOffset || document.documentElement.scrollTop
		const rect = menu.getBoundingClientRect()

		if (window.innerWidth < module.bp.min.lg) {
			if (rect.top === HEADER_HEIGHT_SCROLLED || topScrollPosition < 1) return
			menu.setAttribute('style', `transform: translateY(-${module.header.size.scrolledDiff}px);`)
		} else {
			if (rect.top > 0) return
			menu.setAttribute('style', 'transform: translateY(0px);')
		}
	}

	const initWindowEvents = () => {
		handleHeaderScrolled()
		handleHeaderMenuPositionFallback()

		scroll.register(handleHeaderScrolled)
		resize.register(handleHeaderMenuPositionFallback)
	}

	const init = ({ shouldScrolledModifier }: State) => {
		setState({
			header: getHeader(),
			menu: getMenu(),
			shouldScrolledModifier,
		})

		initWindowEvents()
	}

	return {
		init,
	}
})()
