import { Activator } from 'component'
import Modal from './modal'
import Select from './select'

const Global = (() => {
	const init = () => {
		const modal = Modal()
		const select = Select()

		Activator.init()
		modal.init()
		select.init()
	}

	return {
		init,
	}
})()

export default Global
