import Nette from 'nette-forms'
import 'webpack-jquery-ui'
import 'webpack-jquery-ui/resizable'
import 'webpack-jquery-ui/sortable'
import $ from 'jquery'
import spectrum from 'spectrum-colorpicker'
import fineUploader from 'fine-uploader/lib/jquery/traditional'
import ace from 'ace-builds/src-min-noconflict/ace'
import Cookies from 'js-cookie'
import Swal from 'sweetalert2'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import 'jQuery-QueryBuilder'

// POLYFILL
import 'polyfill'

// IE 11 optimalizace
import 'core-js/es/object'
import 'core-js/es/string'
import 'core-js/es/array'
import 'core-js/es/map'
import 'core-js/es/weak-map'
import 'core-js/es/weak-set'

// import './_elis/resizer'
import './_elis/filter/customizedJqueryUi'

import * as Highcharts from 'highcharts'
import * as HC_solidgauge from 'highcharts/modules/solid-gauge'
import * as HC_funnel from 'highcharts/modules/funnel'
import * as HC_exporting from 'highcharts/modules/exporting'
import * as HC_exporting_OFFLINE from 'highcharts/modules/offline-exporting'
import * as HC_exportData from 'highcharts/modules/export-data'
import * as HC_more from 'highcharts/highcharts-more'
import * as HC_heatmap from 'highcharts/modules/heatmap'
import * as HC_wordcloud from 'highcharts/modules/wordcloud'
// import * as HC_regression from 'highcharts-regression'
import * as HC_boost from 'highcharts/modules/boost'
import * as HC_regression from 'highcharts-regression'
import * as HC_3D from 'highcharts/highcharts-3d'
import * as HC_map from 'highcharts/modules/map'

/* TypeScript */
import { App } from 'app'

/* JavaScript */
import { alerts } from './_elis/alerts'
import { resizetrigger } from './_elis/resizer'

import * as appFunctions from './_elis/$_functions'
import * as filterController from './_elis/filter-controller'
import * as responsiveMenu from './_elis/responsive_menu'
import * as f from './_elis/filter/filter'
import * as srcExport from './_assets/zcanvas/scrExport'
import * as RGBColor from './_assets/zcanvas/rgbcolor'
import * as generatePassword from './_elis/generatepassword'
import { client } from './_elis/client'

if ($) {
	$.extend({ spectrum })
	$.extend({ fineUploader })
}

window.jQuery = $
window.$ = $
window.Cookies = Cookies

// Nette Forms
// window.Nette = Nette // přidáno do layout jako link na www jinak nefunguje toggle ve fromulářích

// Settings
window.blockAttributeData = {}

// Ace
window.ace = ace

// Highcharts
HC_boost(Highcharts)
HC_wordcloud(Highcharts)
HC_heatmap(Highcharts)
HC_more(Highcharts)
HC_solidgauge(Highcharts)
HC_funnel(Highcharts)
HC_exporting(Highcharts)
HC_exporting_OFFLINE(Highcharts)
HC_exportData(Highcharts)
HC_regression(Highcharts)
HC_3D(Highcharts)
HC_map(Highcharts)

Highcharts.setOptions({
	boost: {
		enabled: true,
		useGPUTranslations: true,
		usePreallocated: true,
		seriesThreshold: 50,
	},
	plotOptions: {
		series: {
			animation: false,
		},
	},
})

// HC_regression(Highcharts)
HC_map(Highcharts)
window.Highcharts = Highcharts
// window.graphs = []

// App functions
window.show = appFunctions.show
window.debounce = appFunctions.debounce
window.makeDebounceAjaxCall = appFunctions.makeDebounceAjaxCall
window.resizetrigger = resizetrigger

// Filter
window.itemChecker = f.itemChecker
window.ajaxChange = f.ajaxChange
window.filterDate = f.filterDate
window.filterSelectChoose = f.filterSelectChoose
window.filterSelectSearch = f.filterSelectSearch
window.filterSelectUpdate = f.filterSelectUpdate

// Responsive menu
window.app = responsiveMenu.app

// Alerts
window.alertAction = alerts.alertAction
window.openAlertModal = alerts.openAlertModal
window.closeAlertModal = alerts.closeAlertModal
window.alertsEvents = alerts.alertsEvents

// Filter controller
window.showHelp = filterController.showHelp

// Generate Password
window.generatePassword = generatePassword.generatePassword
window.copyToClipboard = generatePassword.copyToClipboard
window.checkPasswordStrength = generatePassword.checkPasswordStrength

// Src export
window.capture = srcExport.capture
window.exportPdf = srcExport.exportPdf
window.nextStep = srcExport.nextStep
window.resizeScreen = srcExport.resizeScreen
window.Swal = Swal

// RGB color
window.RGBColor = RGBColor.RGBColor

/**
 * Create new ELIS scripts instance -> Init
 */
try {
	client()
	const app = App()
	app.init()
} catch (e) {
	throw Error(e)
}
